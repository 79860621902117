<template>
<div>
  <el-form label-width="auto" :model="form" :rules="rules" ref="form" status-icon>
  <el-form-item label="新密码：" prop="newpass">
    <el-input v-model="form.newpass" type="password" :maxlength="16"></el-input>
  </el-form-item>
  <el-form-item label="重复新密码：" prop="repeat">
    <el-input v-model="form.repeat" type="password" :maxlength="16"></el-input>
  </el-form-item>
  <el-form-item>
    <el-button type="primary" @click="submit">更改密码</el-button>
  </el-form-item>
</el-form>
  </div>
</template>

<script>
import {user} from '../api'
export default {
  name: "rePass",
   data() {
     var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else if(value.length<8||value.length>16){
          callback(new Error('密码长度为8-16位'));
        } else if(value.indexOf(' ')>-1){
          callback(new Error('密码不能包含空格'));
        } else {
          if (this.form.repeat !== '') {
            this.$refs.form.validateField('repeat');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.form.newpass) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return {
        form: {
          newpass: '',
          repeat: '',
        },
        rules: {
          newpass: [
            { required: true, message: '密码不能为空'},
            { validator: validatePass, trigger: 'blur' }
          ],
          repeat: [
            { required: true, message: '密码不能为空'},
            { validator: validatePass2, trigger: 'blur' }
          ]
        }
      }
   },
   methods:{
    submit(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          user.repass({
            password:this.form.newpass,
          }).then((res) => {
            this.$message({
              message: res.msg,
              type: 'success'
            });
            this.$store.commit('delToken');
            this.$router.push('/login');
          })
        } else {
          return false;
        }
      });
    }
   }
}
</script>

<style scoped lang="less">
.el-form{
  padding:10px;
  background-color: #fff;
  .el-form-item{
    margin:0 0 15px 0;
    .el-button{
      width:100%;
    }
  }
}
</style>